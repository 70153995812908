<template>
  <div class="container-fluid p-4">
    <h1>Workflow</h1>
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between pt-1 pb-4">
          <div class="d-flex justify-content-start">
            <router-link
              :to="{ name: 'settings/workflow' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a :href="href" @click="navigate" class="mr-2"
                ><i class="fa fa-chevron-left mr-1"></i>Back</a
              >
            </router-link>
          </div>
          <div class="d-flex justify-content-end w-30 d-inline-block"></div>
        </div>

        <div class="d-flex justify-content-between">
          <!-- title workflow -->
          <b-form-group label="Edit Workflow" label-for="title-input">
            <b-form-input
              id="title-input"
              v-model="title"
              placeholder="Name your workflow (optional)"
              size="sm"
              style="width: 300px; margin-right: 0.5rem"
            ></b-form-input>
          </b-form-group>

          <!-- update workflow -->
          <b-button
            class="align-self-center"
            variant="success"
            size="sm"
            @click="handleUpdateWorkflow"
            ><i class="fa fa-save mr-1"></i>Update
          </b-button>
        </div>

        <div class="bg-white row py-4 mx-1">
          <!-- trigger -->
          <div
            class="col-2 border-light border-right"
            :style="{ borderColor: 'rgba(18, 18, 18, 0.125) !important' }"
          >
            <h3>Trigger</h3>
            <draggable
              class="dragArea list-group"
              :list="triggers"
              :group="{ name: 'trigger', pull: 'clone', put: false }"
              :clone="cloneTrigger"
              @change="log"
            >
              <div
                class="list-group-item"
                v-for="(element, index) in triggers"
                :key="index"
              >
                {{ element.name }}
              </div>
            </draggable>
          </div>

          <!-- selected workflow -->
          <div
            class="col-5 border-light border-right"
            :style="{ borderColor: 'rgba(18, 18, 18, 0.125) !important' }"
          >
            <div class="d-flex justify-content-center align-items-center">
              <div class="mr-2"><h3>When</h3></div>
              <!-- selected workflow event type -->
              <multiselect
                class="w-50"
                v-model="getSelectedWorkflowEventType"
                :options="getEventTypes"
                placeholder="Select one"
                track-by="name"
                label="name"
                :show-labels="true"
                selectLabel=""
                :allow-empty="true"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{
                      eventName(props.option)
                    }}</span>
                  </span>
                </template>
              </multiselect>

              <!-- selected workflow sub event type -->
              <multiselect
                v-model="getSelectedWorkflowSubEventType"
                :options="getSubEventTypes"
                placeholder="Select one"
                track-by="name"
                label="name"
                :show-labels="true"
                selectLabel=""
                :allow-empty="true"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{
                      subEventName(props.option)
                    }}</span>
                  </span>
                </template>
              </multiselect>
              <div class="ml-2"><h3>at</h3></div>
            </div>

            <!-- schedule trigger -->
            <div
              v-if="scheduleTrigger"
              class="list-group-item d-flex justify-content-between mt-3"
            >
              <div>
                {{ scheduleTrigger.value | humanizeText }}
              </div>
              <i
                class="fas fa-edit mr-2 py-1"
                @click="showWorkflowScheduleModal"
              ></i>
            </div>

            <!-- schedule modal -->
            <b-modal
              id="workflow_schedule_modal"
              ref="workflow_schedule_modal"
              title="Workflow Schedule"
              size="xl"
              @show="reset_workflow_schedule_modal"
              @hidden="reset_workflow_schedule_modal"
              @ok="handle_ok_workflow_schedule_modal"
            >
              <table class="d-flex justify-content-center table-responsive">
                <tbody class="table-responsive">
                  <tr>
                    <th><strong>All</strong></th>
                    <td
                      v-for="(day2, key2) in this
                        .groupedWorkflowSchedulesExpand[this.day_of_month]"
                      :key="key2"
                    >
                      <strong>{{ key2 }}</strong>
                    </td>
                  </tr>
                  <tr
                    v-for="(day, key) in groupedWorkflowSchedulesExpand"
                    :key="key"
                  >
                    <th scope="row">{{ getDay(key) }}</th>
                    <td
                      v-for="(segment, index) in day"
                      :key="day + '_' + index"
                    >
                      <div
                        :class="segment.status === 1 ? 'bg-success' : 'bg-dark'"
                        :style="{ height: '30px', width: '30px' }"
                        @click="
                          setWorkflowScheduleBasedOnDayAndIndex(
                            key,
                            index,
                            segment
                          )
                        "
                      ></div>
                    </td>
                  </tr>
                  <tr class="d-flex justify-content-center">
                    <td>
                      <div class="d-flex flex-column h-100">
                        <div
                          class="m-1 d-flex bg-success"
                          :class="
                            setScheduleOn
                              ? 'border border-primary border-3'
                              : null
                          "
                          :style="{ height: '30px', width: '30px' }"
                          @click="handleSetScheduleChange(true)"
                        >
                          <br />
                          &nbsp;
                        </div>
                        On
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-column h-100">
                        <div
                          class="m-1 d-flex bg-dark"
                          :class="
                            !setScheduleOn
                              ? 'border border-primary border-3'
                              : null
                          "
                          :style="{ height: '30px', width: '30px' }"
                          @click="handleSetScheduleChange(false)"
                        >
                          <br />
                          &nbsp;
                        </div>
                        Off
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-modal>

            <!-- selected trigger -->
            <draggable
              class="dragArea list-group"
              :list="selectedTriggers"
              group="trigger"
              @change="log"
            >
              <div
                class="list-group-item"
                v-for="(element, index) in selectedTriggers"
                :key="index"
              >
                <div class="d-flex justify-content-start">
                  <i
                    class="fas fa-trash-alt mr-2 py-1 text-danger"
                    @click="removeTriggerAt(index)"
                  ></i>
                  {{ element.value | humanizeText }}
                </div>
                <!-- category trigger -->
                <multiselect
                  :id="element.value + '_' + index"
                  v-model="element.data"
                  :options="getTriggerCategoriesByTrigger(element.value)"
                  placeholder="Select one"
                  track-by="value"
                  label="name"
                  :show-labels="true"
                  selectLabel=""
                  :allow-empty="true"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{
                        triggerCategoryLabelById(
                          element.value,
                          props.option.value
                        )
                      }}</span>
                    </span>
                  </template>
                </multiselect>
              </div>
            </draggable>
            <div
              class="list-group-item text-muted"
              :style="{ borderStyle: 'dashed' }"
            >
              Drag Trigger Here
            </div>
          </div>

          <div
            class="col-3 border-light border-right"
            :style="{ borderColor: 'rgba(18, 18, 18, 0.125) !important' }"
          >
            <h3>Then do</h3>
            <draggable
              class="dragArea list-group"
              :list="selectedActions"
              group="action"
              @change="log"
            >
              <div
                class="list-group-item d-flex justify-content-between"
                v-for="(element, index) in selectedActions"
                :key="index"
              >
                <div>
                  <i
                    class="fas fa-trash-alt mr-2 py-1 text-danger"
                    @click="removeActionAt(index)"
                  ></i>
                  {{ element.name }}
                </div>
                <i
                  v-if="showEditIcon(element)"
                  class="fas fa-edit mr-2 py-1"
                  @click="showModal(element)"
                ></i>
                <!-- <div>
                  {{ element.data }}
                </div> -->
              </div>
            </draggable>
            <div
              class="list-group-item text-muted"
              :style="{ borderStyle: 'dashed' }"
              v-if="actions.length > 0"
            >
              Drag Action Here
            </div>
          </div>

          <div class="col-2">
            <h3>Actions</h3>
            <draggable
              class="list-group"
              :list="actions"
              group="action"
              @change="log"
            >
              <div
                class="list-group-item"
                v-for="element in actions"
                :key="element.name"
              >
                {{ element.name }}
              </div>
            </draggable>
          </div>
        </div>

        <!-- http api -->
        <b-modal
          id="http_api_modal"
          ref="http_api_modal"
          title="HTTP API"
          size="xl"
          @show="reset_http_api_modal"
          @hidden="reset_http_api_modal"
          @ok="handle_ok_http_api_modal"
        >
          <!--          <pre>-->
          <!--            {{ http_api_form }}-->
          <!--          </pre>-->
          <form
            ref="http_api_form"
            @submit.stop.prevent="handle_submit_http_api_modal"
          >
            <b-form-group
              label="Method"
              label-for="method-input"
              invalid-feedback="Method is required"
              :state="http_api_form_state"
            >
              <b-form-select
                id="method-input"
                v-model="http_api_form.method"
                :state="http_api_form_state"
                required
              >
                <b-form-select-option value="GET">GET</b-form-select-option>
                <b-form-select-option value="POST">POST</b-form-select-option>
                <b-form-select-option value="UPDATE"
                  >UPDATE</b-form-select-option
                >
                <b-form-select-option value="DELETE"
                  >DELETE</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="URL"
              label-for="url-input"
              invalid-feedback="URL is required"
              :state="http_api_form_state"
            >
              <b-form-input
                id="url-input"
                v-model="http_api_form.url"
                :state="http_api_form_state"
                required
              ></b-form-input>
            </b-form-group>

            <p>JSON Data</p>
            <vue-json-editor
              v-model="http_api_form.json_data"
              mode="code"
              :expandedOnStart="true"
            ></vue-json-editor>
            <div>
              Hint: After clicking on a text field, selecting from the Available
              Event Data below will input the value at your text cursor
            </div>

            <hr />

            <div no-body>
              <div
                v-b-toggle.http_api_headers_cookies
                class="d-flex justify-content-between"
              >
                <div class="h6">
                  <strong> Headers and Cookies </strong>
                </div>

                <span class="when-opened">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i
                ></span>
                <span class="when-closed">
                  <i class="fa fa-chevron-up" aria-hidden="true"></i
                ></span>
              </div>

              <b-collapse id="http_api_headers_cookies" role="tabpanel">
                <p>Headers</p>
                <vue-json-editor
                  v-model="http_api_form.headers"
                  mode="code"
                  :expandedOnStart="true"
                ></vue-json-editor>

                <p>Cookies</p>
                <vue-json-editor
                  v-model="http_api_form.cookies"
                  mode="code"
                  :expandedOnStart="true"
                ></vue-json-editor>
              </b-collapse>
            </div>

            <hr />

            <div no-body>
              <div
                v-b-toggle.http_api_authorization_settings
                class="d-flex justify-content-between"
              >
                <div class="h6">
                  <strong> Authorization Settings </strong>
                </div>

                <span class="when-opened">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i
                ></span>
                <span class="when-closed">
                  <i class="fa fa-chevron-up" aria-hidden="true"></i
                ></span>
              </div>

              <b-collapse id="http_api_authorization_settings" role="tabpanel">
                <b-form-group
                  label="Authentication Delay"
                  label-for="auth_delay-input"
                >
                  <b-form-input
                    type="number"
                    id="auth_delay-input"
                    v-model="http_api_form.auth_delay"
                  ></b-form-input>
                  <b-form-text
                    >Delay between authentication and request
                    (seconds)</b-form-text
                  >
                </b-form-group>

                <b-form-group
                  label="Authentication API Response Key"
                  label-for="auth_api_response_key-input"
                >
                  <b-form-input
                    id="auth_api_response_key-input"
                    v-model="http_api_form.auth_api_response_key"
                  ></b-form-input>
                </b-form-group>
              </b-collapse>
            </div>

            <hr />

            <div no-body>
              <div
                v-b-toggle.http_api_ssl_settings
                class="d-flex justify-content-between"
              >
                <div class="h6">
                  <strong> SSL Settings </strong>
                </div>

                <span class="when-opened">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i
                ></span>
                <span class="when-closed">
                  <i class="fa fa-chevron-up" aria-hidden="true"></i
                ></span>
              </div>

              <b-collapse id="http_api_ssl_settings" role="tabpanel">
                <b-form-checkbox
                  v-model="http_api_form.ssl_strict"
                  :value="true"
                  :unchecked-value="false"
                >
                  Use SSL
                </b-form-checkbox>

                <b-form-group label="CA File" label-for="cafile-input">
                  <b-form-input
                    id="cafile-input"
                    v-model="http_api_form.cafile"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Public Certification"
                  label-for="pub_cert-input"
                >
                  <b-form-input
                    id="pub_cert-input"
                    v-model="http_api_form.pub_cert"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Private Certification"
                  label-for="pri_cert-input"
                >
                  <b-form-input
                    id="pri_cert-input"
                    v-model="http_api_form.pri_cert"
                  ></b-form-input>
                </b-form-group>
              </b-collapse>
            </div>
          </form>
        </b-modal>

        <!-- hcb vri -->
        <b-modal
          id="hcb_vri_modal"
          ref="hcb_vri_modal"
          title="HCB VRI"
          size="xl"
          @show="reset_hcb_vri_modal"
          @hidden="reset_hcb_vri_modal"
          @ok="handle_ok_hcb_vri_modal"
        >
          <form
            ref="hcb_vri_form"
            @submit.stop.prevent="handle_submit_hcb_vri_modal"
          >
            <b-form-group
              label="IP Address"
              label-for="ip_addr-input"
              invalid-feedback="IP Address is required"
              :state="hcb_vri_form_state"
            >
              <b-form-input
                id="ip_addr-input"
                v-model="hcb_vri_form.ip_addr"
                :state="hcb_vri_form_state"
                required
              ></b-form-input>
              <b-form-text>eg: 192.168.1.206</b-form-text>
            </b-form-group>

            <b-form-group
              label="TCP Port Number"
              label-for="tcp_port-input"
              invalid-feedback="TCP Port Number is required"
              :state="hcb_vri_form_state"
            >
              <b-form-input
                type="number"
                id="tcp_port-input"
                v-model="hcb_vri_form.tcp_port"
                :state="hcb_vri_form_state"
                required
              ></b-form-input>
              <b-form-text>eg: 6230</b-form-text>
            </b-form-group>

            <b-form-group
              label="Reader"
              label-for="reader_no-input"
              invalid-feedback="Reader is required"
              :state="hcb_vri_form_state"
            >
              <b-form-input
                id="reader_no-input"
                v-model="hcb_vri_form.reader_no"
                :state="hcb_vri_form_state"
                required
              ></b-form-input>
              <b-form-text>eg: 01</b-form-text>
            </b-form-group>
          </form>
        </b-modal>

        <!-- create case -->
        <b-modal
          id="create_case_modal"
          ref="create_case_modal"
          title="Create Case"
          size="xl"
          @show="reset_create_case_modal"
          @hidden="reset_create_case_modal"
          @ok="handle_ok_create_case_modal"
        >
          <form
            ref="create_case_form"
            @submit.stop.prevent="handle_submit_create_case_modal"
          >
            <vue-editor v-model="create_case_form.description" />
          </form>
        </b-modal>

        <!-- websocket alert -->
        <b-modal
          id="websocket_alert_modal"
          ref="websocket_alert_modal"
          title="Websocket Alert"
          size="xl"
          @show="reset_websocket_alert_modal"
          @hidden="reset_websocket_alert_modal"
          @ok="handle_ok_websocket_alert_modal"
        >
          <form
            ref="websocket_alert_form"
            @submit.stop.prevent="handle_submit_websocket_alert_modal"
          >
            <b-form-group
              label="PUBSUB Topic"
              label-for="topic-input"
              invalid-feedback="PUBSUB Topic is required"
              :state="websocket_alert_form_state"
            >
              <b-form-input
                id="topic-input"
                v-model="websocket_alert_form.topic"
                :state="websocket_alert_form_state"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Alert Priority"
              label-for="alert_priority-input"
              invalid-feedback="Alert Priority is required"
              :state="websocket_alert_form_state"
            >
              <b-form-select
                id="alert_priority-input"
                v-model="websocket_alert_form.alert_priority"
                :state="websocket_alert_form_state"
                required
              >
                <b-form-select-option :value="0">Auto</b-form-select-option>
                <b-form-select-option :value="1">Low</b-form-select-option>
                <b-form-select-option :value="2">Default</b-form-select-option>
                <b-form-select-option :value="3">High</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </form>
        </b-modal>

        <!-- alert wall -->
        <b-modal
          id="alert_wall_modal"
          ref="alert_wall_modal"
          title="Alert Wall"
          size="xl"
          @show="reset_alert_wall_modal"
          @hidden="reset_alert_wall_modal"
          @ok="handle_ok_alert_wall_modal"
        >
          <form
            ref="alert_wall_form"
            @submit.stop.prevent="handle_submit_alert_wall_modal"
          >
            <b-form-group
              label="Alert Priority"
              label-for="alert_priority-input"
              invalid-feedback="Alert Priority is required"
              :state="alert_wall_form_state"
            >
              <b-form-select
                id="alert_priority-input"
                v-model="alert_wall_form.alert_priority"
                :state="alert_wall_form_state"
                required
              >
                <b-form-select-option :value="0">Auto</b-form-select-option>
                <b-form-select-option :value="1">Low</b-form-select-option>
                <b-form-select-option :value="2">Default</b-form-select-option>
                <b-form-select-option :value="3">High</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </form>
        </b-modal>

        <!-- psim alert -->
        <b-modal
          id="psim_alert_modal"
          ref="psim_alert_modal"
          title="PSIM Alert"
          size="xl"
          @show="reset_psim_alert_modal"
          @hidden="reset_psim_alert_modal"
          @ok="handle_ok_psim_alert_modal"
        >
          <form
            ref="psim_alert_form"
            @submit.stop.prevent="handle_submit_psim_alert_modal"
          >
            <b-form-group
              label="Alert Priority"
              label-for="alert_priority-input"
              invalid-feedback="Alert Priority is required"
              :state="psim_alert_form_state"
            >
              <b-form-select
                id="alert_priority-input"
                v-model="psim_alert_form.alert_priority"
                :state="psim_alert_form_state"
                required
              >
                <b-form-select-option :value="0">Auto</b-form-select-option>
                <b-form-select-option :value="1">Low</b-form-select-option>
                <b-form-select-option :value="2">Default</b-form-select-option>
                <b-form-select-option :value="3">High</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </form>
        </b-modal>

        <!-- zone people counter -->
        <b-modal
          id="zone_people_counter_modal"
          ref="zone_people_counter_modal"
          title="Zone People Counter"
          size="xl"
          @show="reset_zone_people_counter_modal"
          @hidden="reset_zone_people_counter_modal"
          @ok="handle_ok_zone_people_counter_modal"
        >
          <form
            ref="zone_people_counter_form"
            @submit.stop.prevent="handle_submit_zone_people_counter_modal"
          >
            <b-form-group
              label="Type"
              label-for="count-input"
              :state="zone_people_counter_form_state"
            >
              <b-form-select
                id="count-input"
                v-model="zone_people_counter_form.count"
                :state="zone_people_counter_form_state"
                required
              >
                <b-form-select-option :value="1"
                  >Increase counter</b-form-select-option
                >
                <b-form-select-option :value="-1"
                  >Decrease counter</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </form>
        </b-modal>

        <!-- hcb vci -->
        <b-modal
          id="hcb_vci_modal"
          ref="hcb_vci_modal"
          title="HCB VCI"
          size="xl"
          @show="reset_hcb_vci_modal"
          @hidden="reset_hcb_vci_modal"
          @ok="handle_ok_hcb_vci_modal"
        >
          <form
            ref="hcb_vci_form"
            @submit.stop.prevent="handle_submit_hcb_vci_modal"
          >
            <multiselect
              v-model="hcb_vci_form.controller"
              :options="getTriggerCategoriesByTrigger('controller')"
              placeholder="Select one"
              track-by="value"
              label="name"
              :show-labels="true"
              selectLabel=""
              :allow-empty="true"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{
                    triggerCategoryLabelById("controller", props.option.value)
                  }}</span>
                </span>
              </template>
            </multiselect>

            <b-form-group
              label="Reader"
              label-for="reader_no-input"
              invalid-feedback="Reader is required"
              :state="hcb_vci_form_state"
            >
              <b-form-input
                id="reader_no-input"
                v-model="hcb_vci_form.reader_no"
                :state="hcb_vci_form_state"
                required
              ></b-form-input>
              <b-form-text>e.g. 00, 01, 02, 03</b-form-text>
            </b-form-group>

            <b-form-group
              label="Door"
              label-for="door_no-input"
              invalid-feedback="Door is required"
              :state="hcb_vci_form_state"
            >
              <b-form-input
                id="door_no-input"
                v-model="hcb_vci_form.door_no"
                :state="hcb_vci_form_state"
                required
              ></b-form-input>
              <b-form-text>e.g. 00, 01, 02, 03</b-form-text>
            </b-form-group>
          </form>
        </b-modal>

        <!-- p1 evim -->
        <b-modal
          id="p1_evim_modal"
          ref="p1_evim_modal"
          title="P1 EVIM"
          size="xl"
          @show="reset_p1_evim_modal"
          @hidden="reset_p1_evim_modal"
          @ok="handle_ok_p1_evim_modal"
        >
          <form
            ref="p1_evim_form"
            @submit.stop.prevent="handle_submit_p1_evim_modal"
          >
            <multiselect
              v-model="p1_evim_form.controller"
              :options="getTriggerCategoriesByTrigger('controller')"
              placeholder="Select one"
              track-by="value"
              label="name"
              :show-labels="true"
              selectLabel=""
              :allow-empty="true"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{
                    triggerCategoryLabelById("controller", props.option.value)
                  }}</span>
                </span>
              </template>
            </multiselect>

            <b-form-group
              label="Event Code"
              label-for="event_code-input"
              :state="p1_evim_form_state"
            >
              <b-form-select
                id="event_code"
                v-model="p1_evim_form.event_code"
                :state="p1_evim_form_state"
                required
              >
                <b-form-select-option :value="'Ca'">Entry</b-form-select-option>
                <b-form-select-option :value="'Cb'">Exit</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </form>
        </b-modal>

        <!-- Lift Floor Access HCB VCI (Kiple) -->
        <b-modal
          id="kipple_modal"
          ref="kipple_modal"
          title="Lift Floor Access HCB VCI (Kiple)"
          size="xl"
          @show="reset_kipple_modal"
          @hidden="reset_kipple_modal"
          @ok="handle_ok_kipple_modal"
        >
          <form
            ref="kipple_form"
            @submit.stop.prevent="handle_submit_kipple_modal"
          >
            <multiselect
              v-model="kipple_form.controller"
              :options="getTriggerCategoriesByTrigger('controller')"
              placeholder="Select one"
              track-by="value"
              label="name"
              :show-labels="true"
              selectLabel=""
              :allow-empty="true"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{
                    triggerCategoryLabelById("controller", props.option.value)
                  }}</span>
                </span>
              </template>
            </multiselect>

            <b-form-group
              label="QR Decode Secret Key"
              label-for="secret_key-input"
              invalid-feedback="Secret Key is required"
              :state="kipple_form_state"
            >
              <b-form-input
                id="secret_key-input"
                v-model="kipple_form.secret_key"
                :state="kipple_form_state"
                required
                type="password"
              ></b-form-input>
            </b-form-group>
          </form>
        </b-modal>

        <!--        <div class="d-flex justify-content-between">-->
        <!--          <pre>-->
        <!--            {{ getTriggerOptions }}-->
        <!--          </pre>-->

        <!--          <pre>-->
        <!--            {{ scheduleTrigger }}-->
        <!--            {{ selectedTriggers }}-->
        <!--          </pre>-->

        <!--          <pre>-->
        <!--            {{ selectedActions }}-->
        <!--          </pre>-->

        <!--          <pre>-->
        <!--            {{ actions }}-->
        <!--          </pre>-->
        <!--        </div>-->

        <!--        <div class="d-flex justify-content-between">-->
        <!--          <pre>{{ getSelectedWorkflowTriggers }}</pre>-->
        <!--          <pre>{{ getSelectedWorkflowActions }}</pre>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import Multiselect from "vue-multiselect";
import vueJsonEditor from "vue-json-editor";
import { VueEditor } from "vue2-editor";
import _ from "lodash";

export default {
  components: {
    draggable,
    Multiselect,
    vueJsonEditor,
    VueEditor
  },
  data: function () {
    return {
      title: null,

      triggers: [],
      scheduleTrigger: null,
      selectedTriggers: [],

      actions: [],
      selectedActions: [],

      groupedWorkflowSchedulesExpand: {},
      setScheduleOn: true,

      http_api_form: {
        url: null,
        cafile: null,
        method: "GET",
        cookies: {},
        headers: {},
        pri_cert: null,
        pub_cert: null,
        auth_type: "NONE",
        json_data: {},
        auth_delay: null,
        auth_token: null,
        ssl_strict: false,
        auth_api_url: null,
        auth_api_body: {},
        auth_api_method: "POST",
        auth_api_headers: {},
        auth_api_response_key: null
      },
      http_api_form_state: null,

      hcb_vri_form: {
        ip_addr: null,
        tcp_port: null,
        reader_no: null
      },
      hcb_vri_form_state: null,

      create_case_form: {
        alert: null,
        assignee: null,
        priority: null,
        description: null
      },
      create_case_form_state: null,

      websocket_alert_form: {
        topic: null,
        json_data: {
          data: {
            event_id: "$event_id",
            metadata: "$metadata",
            device_ip: "$device_ip",
            timestamp: "$timestamp",
            device_url: "$device_url",
            object_str: "$object_str",
            device_type: "$device_type",
            object_type: "$object_type"
          },
          type: "$subevent_type"
        },
        alert_priority: 0
      },
      websocket_alert_form_state: null,

      alert_wall_form: {
        alert_priority: 0
      },
      alert_wall_form_state: null,

      psim_alert_form: {
        alert_priority: 0
      },
      psim_alert_form_state: null,

      zone_people_counter_form: {
        count: 1
      },
      zone_people_counter_form_state: null,

      hcb_vci_form: {
        door_no: null,
        reader_no: null,
        controller: null
      },
      hcb_vci_form_state: null,

      p1_evim_form: {
        controller: null,
        event_code: null
      },
      p1_evim_form_state: null,

      kipple_form: {
        controller: null,
        secret_key: null
      },
      kipple_form_state: null,
      day_of_month: null
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",

      getTriggerOptions: "workflowModule/getTriggerOptions",
      getEventTypes: "workflowModule/getEventTypes",
      getSubEventTypes: "workflowModule/getSubEventTypes",
      getTriggerCategories: "workflowModule/getTriggerCategories",
      getSelectedWorkflowTitle: "workflowModule/getSelectedWorkflowTitle",
      getSelectedWorkflow: "workflowModule/getSelectedWorkflow",
      getActionOptions: "workflowModule/getActionOptions",
      getSelectedWorkflowTriggers: "workflowModule/getSelectedWorkflowTriggers",
      getSelectedWorkflowActions: "workflowModule/getSelectedWorkflowActions"
    }),
    getSelectedWorkflowEventType: {
      get() {
        return this.$store.getters[
          "workflowModule/getSelectedWorkflowEventType"
        ];
      },
      set(val) {
        console.log("val", val);
        this.$store.dispatch(
          "workflowModule/setSelectedWorkflowEventTypeObj",
          val
        );
        this.selectedTriggers = [];
      }
    },
    getSelectedWorkflowSubEventType: {
      get() {
        return this.$store.getters[
          "workflowModule/getSelectedWorkflowSubEventType"
        ];
      },
      set(val) {
        console.log("val", val);
        this.$store.dispatch(
          "workflowModule/setSelectedWorkflowSubEventTypeObj",
          val
        );
      }
    },
    getTriggerCategoriesByTrigger: function () {
      return (trigger) => {
        try {
          if (
            Object.prototype.hasOwnProperty.call(
              this.getTriggerCategories,
              trigger
            )
          ) {
            return this.getTriggerCategories[trigger];
          }
        } catch (e) {
          //
          console.log(e);
        }
        return [];
      };
    },
    triggerCategoryLabelById: function () {
      return (trigger, id) => {
        try {
          if (
            Object.prototype.hasOwnProperty.call(
              this.getTriggerCategories,
              trigger
            )
          ) {
            for (
              let i = 0;
              i < this.getTriggerCategories[trigger].length;
              i++
            ) {
              if (this.getTriggerCategories[trigger][i].value === id) {
                return this.getTriggerCategories[trigger][i].name;
              }
            }
          }
        } catch (e) {
          //
          console.log(e);
        }
        return "-";
      };
    }
  },
  watch: {
    $route: function () {
      if (this.$route.params.workflow_id) {
        this.$store.dispatch(
          "workflowModule/fetchWorkflow",
          this.$route.params.workflow_id
        );
        this.$store.dispatch(
          "workflowModule/fetchWorkflowTriggers",
          this.$route.params.workflow_id
        );
        this.$store.dispatch(
          "workflowModule/fetchWorkflowActions",
          this.$route.params.workflow_id
        );
      }
    },

    getTriggerOptions: {
      handler(newValue, oldValue) {
        console.log("getTriggerOptions", newValue);
        this.triggers = newValue;
        /* this.triggers = [];
      for (let i = 0; i < n.length; i++) {
        if (n[i].value === "schedule") {
          //this.scheduleTrigger = n[i]
        } else {
          this.triggers.push(n[i]);
        }
      } */
      },
      deep: true
    },
    getSelectedWorkflowTitle: {
      handler(newValue, oldValue) {
        console.log("getSelectedWorkflowTitle", newValue);
        this.title = newValue;
        /* if (newValue != null) {
          if (newValue.length > 10) {
            this.title = null;
          } else {
            this.title = newValue;
          }
        } */
      },
      deep: true
    },
    getSelectedWorkflowEventType: function (newValue) {
      console.log("getSelectedWorkflowEventType", newValue);
      this.$store.dispatch("workflowModule/fetchActionOptions", newValue);
      this.$store.dispatch("workflowModule/fetchTriggerOptions", newValue);
    },
    getEventTypes: {
      handler(newValue, oldValue) {
        console.log("getEventTypes", newValue);
      },
      deep: true
    },
    getActionOptions: function (newValue) {
      console.log("getActionOptions", newValue);
      //this.actions = n;

      this.selectedActions = [];
      this.actions = [];

      let found1 = false;

      for (let i = 0; i < newValue.length; i++) {
        for (let j = 0; j < this.getSelectedWorkflowActions.length; j++) {
          if (
            this.getSelectedWorkflowActions[j]["action"] ===
            newValue[i]["value"]
          ) {
            found1 = true;
            this.selectedActions.push({
              name: newValue[i]["name"],
              value: newValue[i]["value"],
              data: this.getSelectedWorkflowActions[j]["data"]
            });
          }
        }

        if (!found1) {
          // for (let j = 0; j < this.getSelectedWorkflowActions.length; j++) {
          //  found1 = true;
          //  this.selectedActions.push({
          //    name: this.getSelectedWorkflowActions[j]["name"],
          //    value: this.getSelectedWorkflowActions[j]["value"],
          //    data: this.getSelectedWorkflowActions[j]["data"]
          // });
          //  }
          this.actions.push(newValue[i]);
        }
      }
    },
    getSelectedWorkflowActions: function (newValue) {
      console.log("getSelectedWorkflowActions", newValue);
      //this.selectedActions = n;
      this.selectedActions = [];
      this.actions = [];

      let found2 = false;

      for (let i = 0; i < this.getActionOptions.length; i++) {
        for (let j = 0; j < newValue.length; j++) {
          if (newValue[j].action === this.getActionOptions[i]["value"]) {
            found2 = true;
            this.selectedActions.push({
              name: this.getActionOptions[i]["name"],
              value: this.getActionOptions[i]["value"],
              data: newValue[j]["data"]
            });
            //console.log("this.selectedActions21", this.selectedActions2);
          }
        }

        if (!found2) {
          //console.log("found22", found2);
          //console.log("this.selectedActions22", this.selectedActions2);
          this.actions.push(this.getActionOptions[i]);
        }
      }
    },
    getSelectedWorkflowTriggers: function (newValue) {
      console.log("getSelectedWorkflowTriggers", newValue);
      for (let key in newValue) {
        if (Object.prototype.hasOwnProperty.call(newValue, key)) {
          if (key === "schedule") {
            //
            this.scheduleTrigger = {
              value: "schedule",
              data: newValue["schedule"]
            };

            let groupedSchedules = this.groupByDay(
              this.scheduleTrigger["data"]
            );

            console.log("groupedSchedules", groupedSchedules);

            let groupedSchedulesExpand = {};

            for (let day in groupedSchedules) {
              let segments = [];
              for (let i = 0; i < groupedSchedules[day].length; i++) {
                let temp = this.segmentExpander(groupedSchedules[day][i]);
                segments = segments.concat(temp);
              }
              groupedSchedulesExpand[day] = segments;
            }

            this.groupedWorkflowSchedulesExpand = groupedSchedulesExpand;

            outer_loop: for (let key in this.groupedWorkflowSchedulesExpand) {
              this.day_of_month = key;
              console.log("key", key);
              break outer_loop;
            }

            console.log(
              "groupedWorkflowSchedulesExpand",
              this.groupedWorkflowSchedulesExpand[this.day_of_month]
            );
          } else {
            for (let i = 0; i < newValue[key].length; i++) {
              this.selectedTriggers.push({
                name: "",
                value: key,
                data: {
                  value: newValue[key][i],
                  name: this.triggerCategoryLabelById(key, newValue[key][i])
                }
              });
            }
          }
        }
      }
    }
    // groupedWorkflowSchedulesExpand: {
    //   handler(newValue, oldValue) {
    //     // Note: `newValue` will be equal to `oldValue` here
    //     // on nested mutations as long as the object itself
    //     // hasn't been replaced.
    //     console.log("groupedWorkflowSchedulesExpand");
    //     console.log(newValue);
    //   },
    //   deep: true
    // }
  },
  mounted: function () {
    /* if (this.$route.params.workflow_id) {
    } */
    console.log(
      "this.$route.params.workflow_id",
      this.$route.params.workflow_id
    );

    this.$store.dispatch(
      "workflowModule/fetchSelectedWorkflow",
      this.$route.params.workflow_id
    );

    this.$store.dispatch(
      "workflowModule/fetchWorkflowTriggers",
      this.$route.params.workflow_id
    );

    this.$store.dispatch(
      "workflowModule/fetchWorkflowActions",
      this.$route.params.workflow_id
    );

    this.$store.dispatch("workflowModule/fetchEventTypes");
    this.$store.dispatch("workflowModule/fetchTriggerCategories");
  },
  methods: {
    getDay(key) {
      if (key == 1) {
        return "Mon";
      }
      if (key == 2) {
        return "Tue";
      }
      if (key == 3) {
        return "Wed";
      }
      if (key == 4) {
        return "Thur";
      }
      if (key == 5) {
        return "Fri";
      }
      if (key == 6) {
        return "Sat";
      }
      if (key == 7) {
        return "Sun";
      }
    },
    cloneTrigger(trigger) {
      return {
        ...trigger
      };
    },
    log: function (evt) {
      window.console.log(evt);

      if (Object.prototype.hasOwnProperty.call(evt, "added")) {
        if (Object.prototype.hasOwnProperty.call(evt.added, "element")) {
          this.showModal(evt.added.element);
        }
      }
    },
    removeTriggerAt(idx) {
      this.selectedTriggers.splice(idx, 1);
    },
    removeActionAt(idx) {
      let data = this.selectedActions[idx];
      this.selectedActions.splice(idx, 1);
      this.actions.push(data);
    },
    eventName(id) {
      if (id === null) {
        return this.getEventTypes[0].name;
      }
      for (let i = 0; i < this.getEventTypes.length; i++) {
        if (this.getEventTypes[i].value === id) {
          return this.getEventTypes[i].name;
        }
      }
    },

    subEventName(id) {
      for (let i = 0; i < this.getSubEventTypes.length; i++) {
        if (this.getSubEventTypes[i].value === id) {
          return this.getSubEventTypes[i].name;
        }
      }
    },

    handleUpdateWorkflow() {
      let $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm update workflow?", {
          centered: true
        })
        .then((value) => {
          if (value) {
            let groupedSchedulesReduce = [];
            for (let day in this.groupedWorkflowSchedulesExpand) {
              groupedSchedulesReduce.push(
                this.segmentsReducer(this.groupedWorkflowSchedulesExpand[day])
              );
            }
            console.log("groupedSchedulesReduce");
            console.log(groupedSchedulesReduce);

            let scheduleData = Array.prototype.concat.apply(
              [],
              groupedSchedulesReduce
            );
            console.log("scheduleData");
            console.log(scheduleData);

            let triggers = [...$this.selectedTriggers];
            triggers.push({
              value: "schedule",
              data: {
                value: scheduleData
              }
            });

            console.log("triggers", triggers);

            $this.$store.dispatch("workflowModule/updateSelectedWorkflow", {
              workflow: {
                pk: $this.$route.params.workflow_id,
                title: $this.title,
                event_type: $this.getSelectedWorkflowEventType,
                subevent_type:
                  $this.getSelectedWorkflowSubEventType === 0
                    ? 0
                    : $this.getSelectedWorkflowSubEventType
                // subevent_type: $this.getSelectedWorkflowSubEventType === 0 ? null : $this.getSelectedWorkflowSubEventType
              },
              triggers: triggers,
              actions: $this.selectedActions,
              message: "Workflow is updated"
            });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    showEditIcon(action) {
      if (action.name === "HTTP API") {
        return true;
      } else if (action.name === "HCB VRI") {
        return true;
      } else if (action.name === "Create Case") {
        return true;
      } else if (action.name === "Websocket Alert") {
        return true;
      } else if (action.name === "Alert Wall") {
        return true;
      } else if (action.name === "PSIM Alert") {
        return true;
      } else if (action.name === "Zone People Counter") {
        return true;
      } else if (action.name === "HCB VCI") {
        return true;
      } else if (action.name === "P1 EVIM") {
        return true;
      } else if (action.name === "Lift Floor Access HCB VCI (Kiple)") {
        return true;
      } else {
        return false;
      }
    },
    showWorkflowScheduleModal() {
      this.$refs["workflow_schedule_modal"].show();
    },
    reset_workflow_schedule_modal() {
      //this.groupedWorkflowSchedulesExpand = {};
    },
    handle_ok_workflow_schedule_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_workflow_schedule_modal();
    },
    handle_submit_workflow_schedule_modal() {
      // // Exit when the form isn't valid
      // if (!this.check_http_api_validity()) {
      //   return;
      // }
      //
      // this.updateSelectedActionData("HTTP API", this.http_api_form);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("workflow_schedule_modal");
      });
    },

    handleSetScheduleChange(value) {
      this.setScheduleOn = value;
    },
    setWorkflowScheduleBasedOnDayAndIndex(day, index, value) {
      console.log("this.setScheduleOn");
      console.log(this.setScheduleOn);
      console.log(day);
      console.log(index);
      console.log(value);

      this.groupedWorkflowSchedulesExpand[day][index]["status"] =
        this.setScheduleOn === true ? 1 : 0;

      console.log("this.groupedWorkflowSchedulesExpand[day][index]");
      console.log(this.groupedWorkflowSchedulesExpand[day][index]);
    },

    showModal(action) {
      if (action.name === "HTTP API") {
        this.$refs["http_api_modal"].show();
        this.$nextTick(() => {
          this.http_api_form = {
            ...this.http_api_form,
            ...action.data
          };
          console.log("this.http_api_form");
          console.log(this.http_api_form);
        });
      } else if (action.name === "HCB VRI") {
        this.$refs["hcb_vri_modal"].show();
        this.$nextTick(() => {
          this.hcb_vri_form = {
            ...this.hcb_vri_form,
            ...action.data
          };

          console.log("this.hcb_vri_form");
          console.log(this.hcb_vri_form);
        });
      } else if (action.name === "Create Case") {
        this.$refs["create_case_modal"].show();
        this.$nextTick(() => {
          this.create_case_form = {
            ...this.create_case_form,
            ...action.data
          };

          console.log("this.create_case_form");
          console.log(this.create_case_form);
        });
      } else if (action.name === "Websocket Alert") {
        this.$refs["websocket_alert_modal"].show();
        this.$nextTick(() => {
          this.websocket_alert_form = {
            ...this.websocket_alert_form,
            ...action.data
          };

          console.log("this.websocket_alert_form");
          console.log(this.websocket_alert_form);
        });
      } else if (action.name === "Alert Wall") {
        this.$refs["alert_wall_modal"].show();
        this.$nextTick(() => {
          this.alert_wall_form = {
            ...this.alert_wall_form,
            ...action.data
          };

          console.log("this.alert_wall_form");
          console.log(this.alert_wall_form);
        });
      } else if (action.name === "PSIM Alert") {
        this.$refs["psim_alert_modal"].show();
        this.$nextTick(() => {
          this.psim_alert_form = {
            ...this.psim_alert_form,
            ...action.data
          };

          console.log("this.psim_alert_form");
          console.log(this.psim_alert_form);
        });
      } else if (action.name === "Zone People Counter") {
        this.$refs["zone_people_counter_modal"].show();
        this.$nextTick(() => {
          this.zone_people_counter_form = {
            ...this.zone_people_counter_form,
            ...action.data
          };

          console.log("this.zone_people_counter_form");
          console.log(this.zone_people_counter_form);
        });
      } else if (action.name === "HCB VCI") {
        this.$refs["hcb_vci_modal"].show();
        this.$nextTick(() => {
          this.hcb_vci_form = {
            ...this.hcb_vci_form,
            ...action.data
          };

          console.log("this.hcb_vci_form");
          console.log(this.hcb_vci_form);
        });
      } else if (action.name === "P1 EVIM") {
        this.$refs["p1_evim_modal"].show();
        this.$nextTick(() => {
          this.p1_evim_form = {
            ...this.p1_evim_form,
            ...action.data
          };

          console.log("this.p1_evim_form");
          console.log(this.p1_evim_form);
        });
      } else if (action.name === "Lift Floor Access HCB VCI (Kiple)") {
        this.$refs["kipple_modal"].show();
        this.$nextTick(() => {
          this.kipple_form = {
            ...this.kipple_form,
            ...action.data
          };

          console.log("this.kipple_form");
          console.log(this.kipple_form);
        });
      }
    },
    // http api
    check_http_api_validity() {
      const valid = this.$refs.http_api_form.checkValidity();
      this.http_api_form_state = valid;
      return valid;
    },
    reset_http_api_modal() {
      this.http_api_form = {
        url: null,
        cafile: null,
        method: "GET",
        cookies: {},
        headers: {},
        pri_cert: null,
        pub_cert: null,
        auth_type: "NONE",
        json_data: {},
        auth_delay: null,
        auth_token: null,
        ssl_strict: false,
        auth_api_url: null,
        auth_api_body: {},
        auth_api_method: "POST",
        auth_api_headers: {},
        auth_api_response_key: null
      };
      this.http_api_form_state = null;
    },
    handle_ok_http_api_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_http_api_modal();
    },
    handle_submit_http_api_modal() {
      // Exit when the form isn't valid
      if (!this.check_http_api_validity()) {
        return;
      }

      this.updateSelectedActionData("HTTP API", this.http_api_form);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("http_api_modal");
      });
    },
    // hcb vri
    check_hcb_vri_validity() {
      const valid = this.$refs.hcb_vri_form.checkValidity();
      this.hcb_vri_form_state = valid;
      return valid;
    },
    reset_hcb_vri_modal() {
      this.hcb_vri_form = {
        ip_addr: null,
        tcp_port: null,
        reader_no: null
      };
      this.hcb_vri_form_state = null;
    },
    handle_ok_hcb_vri_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_hcb_vri_modal();
    },
    handle_submit_hcb_vri_modal() {
      // Exit when the form isn't valid
      if (!this.check_hcb_vri_validity()) {
        return;
      }

      this.updateSelectedActionData("HCB VRI", this.hcb_vri_form);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("hcb_vri_modal");
      });
    },
    // create case
    check_create_case_validity() {
      const valid = this.$refs.create_case_form.checkValidity();
      this.create_case_form_state = valid;
      return valid;
    },
    reset_create_case_modal() {
      this.create_case_form = {
        alert: null,
        assignee: null,
        priority: null,
        description: null
      };
      this.create_case_form_state = null;
    },
    handle_ok_create_case_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_create_case_modal();
    },
    handle_submit_create_case_modal() {
      // Exit when the form isn't valid
      if (!this.check_create_case_validity()) {
        return;
      }

      this.updateSelectedActionData("Create Case", this.create_case_form);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("create_case_modal");
      });
    },
    // websocket alert
    check_websocket_alert_validity() {
      const valid = this.$refs.websocket_alert_form.checkValidity();
      this.websocket_alert_form_state = valid;
      return valid;
    },
    reset_websocket_alert_modal() {
      this.websocket_alert_form = {
        topic: null,
        json_data: {
          data: {
            event_id: "$event_id",
            metadata: "$metadata",
            device_ip: "$device_ip",
            timestamp: "$timestamp",
            device_url: "$device_url",
            object_str: "$object_str",
            device_type: "$device_type",
            object_type: "$object_type"
          },
          type: "$subevent_type"
        },
        alert_priority: 0
      };
      this.websocket_alert_form_state = null;
    },
    handle_ok_websocket_alert_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_websocket_alert_modal();
    },
    handle_submit_websocket_alert_modal() {
      // Exit when the form isn't valid
      if (!this.check_websocket_alert_validity()) {
        return;
      }

      this.updateSelectedActionData(
        "Websocket Alert",
        this.websocket_alert_form
      );

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("websocket_alert_modal");
      });
    },
    // alert wall
    check_alert_wall_validity() {
      const valid = this.$refs.alert_wall_form.checkValidity();
      this.alert_wall_form_state = valid;
      return valid;
    },
    reset_alert_wall_modal() {
      this.alert_wall_form = {
        alert_priority: 0
      };
      this.alert_wall_form_state = null;
    },
    handle_ok_alert_wall_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_alert_wall_modal();
    },
    handle_submit_alert_wall_modal() {
      // Exit when the form isn't valid
      if (!this.check_alert_wall_validity()) {
        return;
      }

      this.updateSelectedActionData("Alert Wall", this.alert_wall_form);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("alert_wall_modal");
      });
    },
    // psim alert
    check_psim_alert_validity() {
      const valid = this.$refs.psim_alert_form.checkValidity();
      this.psim_alert_form_state = valid;
      return valid;
    },
    reset_psim_alert_modal() {
      this.psim_alert_form = {
        alert_priority: 0
      };
      this.psim_alert_form_state = null;
    },
    handle_ok_psim_alert_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_psim_alert_modal();
    },
    handle_submit_psim_alert_modal() {
      // Exit when the form isn't valid
      if (!this.check_psim_alert_validity()) {
        return;
      }

      this.updateSelectedActionData("PSIM Alert", this.psim_alert_form);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("psim_alert_modal");
      });
    },
    // zone people counter
    check_zone_people_counter_validity() {
      const valid = this.$refs.zone_people_counter_form.checkValidity();
      this.zone_people_counter_form_state = valid;
      return valid;
    },
    reset_zone_people_counter_modal() {
      this.zone_people_counter_form = {
        count: 1
      };
      this.zone_people_counter_form_state = null;
    },
    handle_ok_zone_people_counter_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_zone_people_counter_modal();
    },
    handle_submit_zone_people_counter_modal() {
      // Exit when the form isn't valid
      if (!this.check_zone_people_counter_validity()) {
        return;
      }

      this.updateSelectedActionData(
        "Zone People Counter",
        this.zone_people_counter_form
      );

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("zone_people_counter_modal");
      });
    },
    // hcb vci
    check_hcb_vci_validity() {
      const valid = this.$refs.hcb_vci_form.checkValidity();
      this.hcb_vci_form_state = valid;
      return valid;
    },
    reset_hcb_vci_modal() {
      this.hcb_vci_form = {
        door_no: null,
        reader_no: null,
        controller: null
      };
      this.hcb_vci_form_state = null;
    },
    handle_ok_hcb_vci_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_hcb_vci_modal();
    },
    handle_submit_hcb_vci_modal() {
      // Exit when the form isn't valid
      if (!this.check_hcb_vci_validity()) {
        return;
      }

      this.updateSelectedActionData("HCB VCI", this.hcb_vci_form);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("hcb_vci_modal");
      });
    },
    // p1 evim
    check_p1_evim_validity() {
      const valid = this.$refs.p1_evim_form.checkValidity();
      this.p1_evim_form_state = valid;
      return valid;
    },
    reset_p1_evim_modal() {
      this.p1_evim_form = {
        controller: null,
        event_code: null
      };
      this.p1_evim_form_state = null;
    },
    handle_ok_p1_evim_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_p1_evim_modal();
    },
    handle_submit_p1_evim_modal() {
      // Exit when the form isn't valid
      if (!this.check_p1_evim_validity()) {
        return;
      }

      this.updateSelectedActionData("P1 EVIM", this.p1_evim_form);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("p1_evim_modal");
      });
    },
    // kipple
    check_kipple_validity() {
      const valid = this.$refs.kipple_form.checkValidity();
      this.kipple_form_state = valid;
      return valid;
    },
    reset_kipple_modal() {
      this.kipple_form = {
        controller: null
      };
      this.kipple_form_state = null;
    },
    handle_ok_kipple_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_kipple_modal();
    },
    handle_submit_kipple_modal() {
      // Exit when the form isn't valid
      if (!this.check_kipple_validity()) {
        return;
      }

      this.updateSelectedActionData(
        "Lift Floor Access HCB VCI (Kiple)",
        this.kipple_form
      );

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("kipple_modal");
      });
    },

    updateSelectedActionData(name, data) {
      for (let i = 0; i < this.selectedActions.length; i++) {
        if (this.selectedActions[i].name === name) {
          this.selectedActions[i].data = data;
        }
      }
    },

    // onJsonChange(value) {
    //   console.log("value:", value);
    // }

    scheduleTranslator() {},

    groupByDay(schedules) {
      return _.groupBy(schedules, (schedule) => schedule.day_of_week);
    },
    segmentExpander(timeSegment) {
      let output = [];
      let tempStart = timeSegment.start_time;
      let tempEnd = timeSegment.start_time;
      while (tempStart !== timeSegment.end_time) {
        tempEnd = tempStart + 3600;
        output.push({
          ...timeSegment,
          start_time: tempStart,
          end_time: tempEnd
        });
        tempStart = tempEnd;
      }
      return output;
    },

    segmentsReducer(segments) {
      let output = [];

      let tempStart = segments[0].start_time;
      let tempEnd = segments[0].end_time;
      let statusFlag = segments[0].status;

      for (let i = 0; i < segments.length; i++) {
        if (segments[i].status === statusFlag) {
          tempEnd = segments[i].end_time;

          if (i === segments.length - 1) {
            output.push({
              ...segments[i],
              status: statusFlag,
              start_time: tempStart,
              end_time: tempEnd
            });
          }
        } else {
          /// push the temp obj
          output.push({
            ...segments[i],
            status: statusFlag,
            start_time: tempStart,
            end_time: tempEnd
          });
          statusFlag = segments[i].status;
          tempStart = segments[i].start_time;
          tempEnd = segments[i].end_time;
        }
      }

      return output;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.border-3 {
  border-width: 3px !important;
}
</style>
